import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Box, Spinner } from 'theme-ui'
import { useAuth, utils } from '@chordcommerce/gatsby-theme-autonomy'
import ErrorAuthenticatePage from '~/components/Error/Authenticate/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'

const Authenticate = () => {
  const { handleCallback, isLoggedIn } = useAuth()
  const [errorRequestId, setErrorRequestId] = useState(null)
  const { getParam } = utils

  const destination = getParam('destination') || '/'

  useEffect(() => {
    handleCallback()
      .then(() => navigate(destination))
      .catch((error) => setErrorRequestId(error?.request_id))
  }, []) // eslint-disable-line

  useEffect(() => {
    if (isLoggedIn) navigate(destination)
  }, [destination, isLoggedIn])

  return (
    <Layout tunneled={true}>
      <Metadata title="Authenticating..." noindex={true} />
      {!errorRequestId && (
        <Box py={10} sx={{ textAlign: 'center' }}>
          <Spinner
            color="brandTertiary"
            size="100"
            sx={{
              '@media (prefers-reduced-motion)': {
                circle: {
                  animationIterationCount: 8,
                },
              },
            }}
          />
        </Box>
      )}
      {errorRequestId && <ErrorAuthenticatePage requestId={errorRequestId} />}
    </Layout>
  )
}

export default Authenticate
